function resizeImg(width, height) {

    function gcd(a, b) {
        return (b == 0) ? a : gcd(b, a % b);
    }

    var w = 1920;
    var h = height;
    var r = gcd(w, h);

    width *= 1;
    return Math.round((width / (w / r)) * (h / r));

};


function doStuff() {
    if ($(window).width() >= 769) {
        $('.first-row').css('height', resizeImg($(window).width(), 610));
        $('.slider-container').css('height', resizeImg($(window).width(), 785));
        $('.first-row').css('margin-top', 'auto');
        $('.brand-logo').show();
    } else {
        $('.first-row').css('margin-top', resizeImg($(window).width(), 670));
        $('.first-row').css('height', 'auto');
        $('.slider-container').css('height', 'auto');
        $('.brand-logo').hide();

    }
}

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

$(document).ready(function () {

    // főoldali contact formon alapból ne legyen semmi bejelölve
    $("#contact_form_homepage_36 input:radio").attr("checked", false);

    // összecsukós START
    $('.expand').addClass('cc').after('<p class="text-center" style="margin-top:15px;"><a href="javascript:void(0)" class="moreInfo">Bővebben<i class="fa fa-angle-down"></i></a></p>');
    $('body').on('click', '.moreInfo', function () {
        if ($(this).parent().prev().hasClass('cc')) {
            $(this).text('Becsuk').prepend("<i class='fa fa-angle-up'></i>").parent().prev().removeClass('cc');
        } else {
            $(this).text('Bővebben').append("<i class='fa fa-angle-down'></i>").parent().prev().addClass('cc');
        }
    });
    // összecsukós END

    $('a[href^="tel:"]').click(function(){ gtag_report_conversion(); return false; });

    /*koronavírus tájékoztató START*/
    if($('.infoBox').length && (document.cookie.indexOf('infoBoxCookieHappiness=') == -1)){
        $('.infoBox').removeClass('hidden');
    }

    $('.infoBox, .info-box-close').click(function () {
        $('.infoBox').addClass('hidden');
        document.cookie = "infoBoxCookieHappiness=accepted";
    });
    /*koronavírus tájékoztató END*/

    // adatvédelem sor a formokban START
    $('#contact_form_1 :submit').before('<p class="terms-line">Az űrlap elküldésével elfogadom az <a href="/adatvedelem" target="_blank">adatvédelmi szabályzatot.</a></p>');

    //secret oldal
    //$('#contact_form_secret_6 :submit').before('<p class="terms-line">Az űrlap elküldésével elfogadom az <a href="/secret-adatvedelem" target="_blank">adatvédelmi szabályzatot.</a></p>');
    // adatvédelem sor a formokban END

    // adatvédelem checkbox START
    if (document.cookie.indexOf('adatvedCookieHappiness=') == -1) {
        $(".adatved_check input:checkbox").change(function () {
            if (this.checked) {
                $(".adatved_thx").removeClass("hidden");
                $(".adatved_check").addClass("hidden");
                document.cookie = "adatvedCookieHappiness=accepted";
            }
        });
    } else {
        $(".adatved_thx").removeClass("hidden");
        $(".adatved_check").addClass("hidden");
    }
    // adatvédelem checkbox END

    // árak link START
    if (window.innerWidth < 768 || window.innerWidth > 1024) {
        $('.pl1').removeAttr('id');
        $('.pl3').removeAttr('id');
        $('.pl2').attr('id', 'price-landing');
    }
    if (window.innerWidth == 768) {
        $('.pl1').removeAttr('id');
        $('.pl2').removeAttr('id');
        $('.pl3').attr('id', 'price-landing');
    }
    if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        $('.pl2').removeAttr('id');
        $('.pl3').removeAttr('id');
        $('.pl1').attr('id', 'price-landing');
    }
    // árak link END

    //slider video oldalbetöltéskor 3mp után indul + kép eltűnik START
    if (window.location.href.indexOf("video-teszt-g") > -1) {
        $('#happy_main_video')[0].pause();
        setTimeout(function () {
            $('#happy_main_video').fadeIn(2000);
            $('#happy_main_video')[0].play();
            if (navigator.userAgent.match(/iPad/i) != null) {
                console.log('ipad :)');
                $('.happy-video-container').css('background', '#f6f4f4');
            }
        }, 3500);

        if ($(window).width() == 1024) {
            $('.happy-video-container .first-row .brand-logo').removeClass('pull-right');
        }
    }
    //slider video oldalbetöltéskor 3mp után indul + kép eltűnik END

    //csak face és body oldalakon jelenjen meg az árak menüpont start
    if (window.location.href.indexOf("face") > -1 || window.location.href.indexOf("body") > -1) {
        $('.price-menu-item').removeClass('hidden');
    }
    //csak face és body oldalakon jelenjen meg az árak menüpont end

    //form elküld gombját ne lehessen többször kattintani start
    $('form[id^="contact_form"]').submit(function () {
        $("input[type='submit']").attr("disabled", "disabled");
        setTimeout('$("input[type=submit]").removeAttr("disabled")', 3500);
    });
    //form elküld gombját ne lehessen többször kattintani end

    // mobilon menü csukódjon "jelentkezz itt"-re kattintás után és az "árak"-ra kattintás után
    if (/Android|webOS|iPhone|iPod|Berry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $(".sign-here-menu-item a").click(function () {
            $('.navbar-collapse').collapse('toggle');
        });
        $(".price-menu-item a").click(function () {
            $('.navbar-collapse').collapse('toggle');
        });
    }

    // köszönő oldalt ne lehessen megosztani start
    if (window.location.href.indexOf("koszonjuk") > -1) {
        $(".fb-share-icon a").attr("href", "https://www.facebook.com/sharer/sharer.php?u=http%3A//www.happiness-clinic.hu/");
    }
    // köszönő oldalt ne lehessen megosztani end

    //térkép tooltip a menüben
    $('[data-toggle="tooltip"]').tooltip();

    // head slider cserélhető szöveg start
    var title = getUrlParameter('title');
    if (title) {
        $('.slider-head-text-container').addClass('hidden');
        $('.body-url-title-' + title + '-container').removeClass('hidden');
        $('.face-url-title-' + title + '-container').removeClass('hidden');
    }
    // head slider cserélhető szöveg end

    //kereső funkció a gyik oldalon START
    //contains fgv-t felülírja gyik_contains-ra
    jQuery.expr[':'].gyik_contains = function (a, i, m) {
        return jQuery(a).text().toUpperCase()
            .indexOf(m[3].toUpperCase()) >= 0;
    };

    //kereső funkció a gyik oldalon START
    $("#gyik_search_input").keyup(function (e) {
        var kif = $('#gyik_search_input').val();
        if (kif.length > 3) {
            $(".faq-item").css('display', 'none');
            $(".faq-item:gyik_contains('" + kif + "')").css('display', 'block');
        } else {
            $(".faq-item").css('display', 'block');
        }
    });
    // kereső funkció END


    new WOW().init();
    doStuff();

    if ($('.body-anim').length || $('.face-anim').length && $('body.edit').length == 0) {

        $('.body-anim').addClass("anim-stop");
        $('.face-anim').addClass("anim-stop");
        $('.fat-anim').addClass("anim-stop");

        setTimeout(function () {

            setInterval(function () {
                $('.fat-anim').toggleClass("anim-stop");
            }, 500);
        }, 500);

        setTimeout(function () {

            setInterval(function () {
                $('.face-anim').toggleClass("anim-stop");
            }, 500);

        }, 500);

        setTimeout(function () {

            setInterval(function () {
                $('.body-anim').toggleClass("anim-stop");
            }, 500);

        }, 500);


    }

    //visszaszámláló
    //Callback works only with direction = "down"
    if ($(".flipTimer").length) {
        var now = new Date();

        //var targetDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);

        var hanyadikaVanMa = now.getDate();
        if (hanyadikaVanMa <= 15) {
            var targetDate3 = new Date(now.getFullYear(), now.getMonth(), +16);
        }
        else {
            var targetDate3 = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        }

        /*Karácsonyi akció 2021 oldalon 2021. dec. 31-ig számoljon START*/
        if ((window.location.href.indexOf("karacsonyi-akcio2021") > -1)) {
            var targetDate3 = "Dec 31, 2021 23:59:59";
        }
        /*Karácsonyi akció 2021 oldalon 2021. dec. 31-ig számoljon END*/

        /*Black Friday 2020 oldalon 2020. dec. 13-ig számoljon START*/
        if ((window.location.href.indexOf("black-friday2020") > -1)) {
            var targetDate3 = "Dec 12, 2020 23:59:59";
        }
        /*Black Friday 2020 oldalon 2020. dec. 13-ig számoljon END*/

        /*karácsonyi akciós oldalon 2018. dec. 24-ig számoljon START*/
        /*if ((window.location.href.indexOf("karacsonyi-akcio") > -1)) {
            var targetDate3 = "Dec 24, 2018 23:59:59";
        }*/
        /*karácsonyi akciós oldalon 2018. dec. 24-ig számoljon END*/

        /*RF-bk oldalon 2018. dec. 11-ig számoljon START*/
        if ((window.location.href.indexOf("radiofrekvencias-borfeszesites-bk") > -1)) {
            var targetDate3 = "Dec 11, 2018 23:59:59";
        }
        /*RF-bk oldalon 2018. dec. 11-ig számoljon END*/

        /*RF-bk1 oldalon 2018. dec. 21-ig számoljon START*/
        if ((window.location.href.indexOf("radiofrekvencias-borfeszesites-bk1") > -1)) {
            var targetDate3 = "Dec 21, 2018 23:59:59";
        }
        /*RF-bk oldalon 2018. dec. 21-ig számoljon END*/

        // face tesztelő oldalon mindig vasárnap éjfélig számoljon START
        /*if((window.location.href.indexOf("face-teszt-bk") > -1 )){
         var targetDate3 = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 7);
         }*/
        // face tesztelő oldalon mindig vasárnap éjfélig számoljon END

        // face tesztelő oldalon 2019.02.12. éjfélig számoljon START
        if ((window.location.href.indexOf("face-teszt-bk2022") > -1)) {
            var targetDate3 = "Jun 19, 2022 23:59:59";
        }
        // face tesztelő oldalon 2019.02.12. éjfélig számoljon END

        // body tesztelő oldalon 2020.03.12. éjfélig számoljon START
        if ((window.location.href.indexOf("body-teszt-bk") > -1)) {
            var targetDate3 = "Mar 12, 2020 00:00:00";
        }
        // face tesztelő oldalon 2019.02.12. éjfélig számoljon END

        // újévi akció (2022) 2022.02.28. éjfélig számoljon START
        if ((window.location.href.indexOf("ujevi-akcio") > -1) || (window.location.href.indexOf("ujevi-akcio-m") > -1)) {
            var targetDate3 = "Feb 28, 2022 23:59:59";
            $(".sign-here-menu-item .sign-here").attr("href", "#ajanlat-block");
        }
        // újévi akció (2022) 2022.02.28. éjfélig számoljon END

        // oxygeneo oldal START
        if ((window.location.href.indexOf("oxygeneo-peeling") > -1)) {
            var targetDate3 = "Sep 31, 2022 23:59:59";
        }
        // oxygeneo oldal END

        // nyári akciós kéthetes váltással 2019-06-24-től START
        //if ((window.location.href.indexOf("-nyariakcio") > -1)) {
        if ($("body").hasClass("nyariakcio")) {
            var hanyadikaVanMa = now.getDate();
            if (hanyadikaVanMa < 9) {
                var targetDate3 = new Date(now.getFullYear(), now.getMonth() + 0, +9);
            }
            else if ((hanyadikaVanMa > 24) && (hanyadikaVanMa <= 31)) {
                var targetDate3 = new Date(now.getFullYear(), now.getMonth() + 1, 9);
            }
            else {
                var targetDate3 = new Date(now.getFullYear(), now.getMonth(), now.getDay() + 23);
            }
        }
        // nyári akciós kéthetes váltással 2019-06-24-től END

        // angol oldalakon vasárnap éjfélig számoljon START
        if ((window.location.href.indexOf("happiness-facelifting.hu") > -1)) {
            //var targetDate3 = "Aug 18, 2019 23:59:59";
            var targetDate3 = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 7, 23, 59, 59);
        }
        // angol oldalakon vasárnap éjfélig számoljon END

//köszönő upsell (24 óráról számol vissza) START
        if ($('.upsell-block').length) {
            var expired = new Date();
            if (window.location.href.indexOf("param") > 0) {
                var redirected = false;
                if (redirected === false) {
                    setTimeout(function () {
                        redirected = true;
                        $('#contact_form_upsell_20').parent().append('<h2 class="text-center">Az akció határideje lejárt!</h2>');
                        $('#contact_form_upsell_20').remove();
                    }, Math.round((parseInt(atob(window.location.href.split("=")[1].split(",")[0]).split(",")[1]) - new Date())));
                }

                var endOfUpsell = new Date(parseInt(atob(window.location.href.split("=")[1].split(",")[0]).split(",")[1]));

                if (endOfUpsell == 'Invalid Date') {
                    var targetDate3 = expired;
                    $('#contact_form_upsell_20').parent().append('<h2 class="text-center">Az akció határideje lejárt!</h2>');
                    $('#contact_form_upsell_20').remove();
                } else {
                    if (endOfUpsell < new Date()) {
                        var targetDate3 = expired;
                        $('#contact_form_upsell_20').parent().append('<h2 class="text-center">Az akció határideje lejárt!</h2>');
                        $('#contact_form_upsell_20').remove();
                    } else {
                        var targetDate3 = endOfUpsell;
                    }
                }
            } else {
                var targetDate3 = expired;
                $('#contact_form_upsell_20').parent().append('<h2 class="text-center">Az akció határideje lejárt!</h2>');
                $('#contact_form_upsell_20').remove();
            }
        }
        //köszönő upsell END


        //var targetDate = "Dec 31, 2017 23:59:59";


        $('.flipTimer').flipTimer({
            direction: 'down',
            date: targetDate3
        });
    }


    /*scroll start*/
    if (window.location.hash) {
        $('html, body').animate({scrollTop: 0}, 0);
        setTimeout(function () {
            target = $(window.location.hash);
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.eq(0).offset().top - 88
                }, 2000);
            }
        }, 800);
    }
    $('body:not(.editMode)').find('a[href*="#"]:not([href="#"], .faq-item .collapsed, .carousel .carousel-control)').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.eq(0).offset().top - 88
                }, 2000);
                return false;
            }
        }
        if ($('.navbar-collapse').hasClass('in')) {
            $('.navbar-collapse').removeClass('in').addClass('collapse');
        }
    });
    /*scroll end*/

    /*secret patch animate up START*/
    /*if($('.toSecret').length){
        setTimeout(function(){
            if($(window).width() < 767){
                $('.toSecret').toggle("slide", {direction: "right"}, 1000);
            }
            else {
                $('.toSecret').slideToggle("slow");
            }
        }, 3000);
    }*/
    /*secret patch animate up END*/

});


//chat START (csak tesztoldalon jelenik meg)
$(window).load(function () {

    if (window.location.href.indexOf("tesztoldal") > -1) {
        if (window.innerWidth < 480) {
            var pwidth = window.innerWidth;
        } else {
            var pwidth = 320;
        }

        var LHCChatOptions = {};
        LHCChatOptions.opt = {widget_height: 340, widget_width: pwidth, popup_height: 520, popup_width: 500};
        (function () {
            var po = document.createElement('script');
            po.type = 'text/javascript';
            po.async = true;
            var referrer = (document.referrer) ? encodeURIComponent(document.referrer.substr(document.referrer.indexOf('://') + 1)) : '';
            var location = (document.location) ? encodeURIComponent(window.location.href.substring(window.location.protocol.length)) : '';
            po.src = '//www.happiness-clinic.hu/chat/index.php/ger/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(hide_offline)/false/(check_operator_messages)/true/(top)/350/(units)/pixels/(department)/1/(theme)/1?r=' + referrer + '&l=' + location;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(po, s);
        })();

        setTimeout(function () {

            if (window.innerWidth > 480) {
                $('#lhc_status_container').css('display', 'none');
                $('#lhc_status_container a').trigger('click');
                $('#lhc_container').animate({bottom: '0px'}, 3000);

                $('#lhc_title a[target="_blank"]').removeAttr('target');
                $('#lhc_title a').attr('href', '#');
                $('#lhc_title a').removeAttr('title');

                setTimeout(function () {
                    $('#lhc_container').animate({bottom: '0px'}, 3000);
                }, 3500);
                setTimeout(function () {
                    $('#lhc_container').addClass('bottomnulla');
                }, 6500);
            } else {
                $('#lhc_status_container').css('display', 'none');
                $('#lhc_status_container a').trigger('click');
                $('#lhc_container').animate({bottom: '20px'}, 1000);

                $('#lhc_title a[target="_blank"]').removeAttr('target');
                $('#lhc_title a').attr('href', '#');
                $('#lhc_title a').removeAttr('title');

                setTimeout(function () {
                    $('#lhc_container').addClass('bottomnullam');
                }, 1000);
            }
        }, 1500);
    }
});
//chat END


$(window).resize(function () {
    doStuff()
});



